import React, { useState, useRef } from 'react';
import ChatArea from './ChatArea';
import Notebook from './Notebook';
import { Flex, Box } from '@chakra-ui/react';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import { useGetGroupsQuery } from '../../store/groupApi';
import { getGroupByRoomId } from './utils';

const Workspace: React.FC = () => {
  const defaultChatWidth = 50; // Default width for ChatArea in percentage
  const [chatWidth, setChatWidth] = useState(defaultChatWidth); // Percentage width for ChatArea
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const activeRoom = useSelector((state: RootState)=> state.room.activeRoom)
  const {data: groups} = useGetGroupsQuery();
  const activeGroup = getGroupByRoomId(activeRoom?.id || '', groups?.groups || []);
  
  // Start dragging
  const handleMouseDown = () => {
    setIsDragging(true);
  };

  // Adjust width while dragging
  const handleMouseMove = (e: MouseEvent) => {
    if (!isDragging) return;
    const newChatWidth = containerRef.current ? ((e.clientX - containerRef.current.offsetLeft) / containerRef.current.clientWidth) * 100 : defaultChatWidth;
    if (newChatWidth >= 25 && newChatWidth <= 75) { // Setting min/max width limits
      setChatWidth(newChatWidth);
    }
  };

  // Stop dragging
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // Attach and clean up event listeners for mouse movements
  React.useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);


  return (
    <>
    {activeRoom && activeGroup ?
      <Flex 
        height="full" 
        width="100%" 
        overflowX="hidden"
        ref={containerRef}>
        {/* ChatArea with resizable width */}
        <Box width={`${chatWidth}%`} height="100%" overflowX="hidden">
            <ChatArea 
              activeRoom={activeRoom}
              activeGroup={activeGroup}
            />
        </Box>

        {/* Draggable Divider */}
        <Box
          width="3px"
          height={"100%"}
          rounded={10}
          my={"auto"}
          cursor="col-resize"
          bg="gray.300"
          onMouseDown={handleMouseDown}
          overflowX="hidden"
        ></Box>

        {/* Notebook taking remaining width */}
        <Box width={`${100 - chatWidth}%`} height="100%" overflowX="hidden">
          <Notebook
            allocatedWidth={100-chatWidth}
          />
        </Box>
      </Flex> :
      <Box
        width={"100%"}
        height={"100%"}
        textAlign={"center"}
        alignContent={"center"}
        >
          Select a chat to start messaging
      </Box>
    }
    </>
  );
};

export default Workspace;
