/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PromptCreate } from '../models/PromptCreate';
import type { PromptOut } from '../models/PromptOut';
import type { PromptsOut } from '../models/PromptsOut';
import type { PromptUpdate } from '../models/PromptUpdate';
import type { SystemResponse } from '../models/SystemResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PromptsService {

    /**
     * Read Prompts
     * Retrieve prompts.
     * @returns PromptsOut Successful Response
     * @throws ApiError
     */
    public static readPrompts({
        experimentId,
        skip,
        limit = 100,
    }: {
        experimentId: string,
        skip?: number,
        limit?: number,
    }): CancelablePromise<PromptsOut> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/prompts/',
            headers: {
                'experiment-id': experimentId,
            },
            query: {
                'skip': skip,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Prompt
     * Create new prompt.
     * @returns PromptOut Successful Response
     * @throws ApiError
     */
    public static createPrompt({
        experimentId,
        requestBody,
    }: {
        experimentId: string,
        requestBody: PromptCreate,
    }): CancelablePromise<PromptOut> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/prompts/',
            headers: {
                'experiment-id': experimentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Prompt
     * Get prompt by ID.
     * @returns PromptOut Successful Response
     * @throws ApiError
     */
    public static readPrompt({
        id,
    }: {
        id: string,
    }): CancelablePromise<PromptOut> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/prompts/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Prompt
     * Delete an prompt.
     * @returns SystemResponse Successful Response
     * @throws ApiError
     */
    public static deletePrompt({
        id,
    }: {
        id: string,
    }): CancelablePromise<SystemResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/prompts/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Prompt
     * @returns PromptOut Successful Response
     * @throws ApiError
     */
    public static updatePrompt({
        promptId,
        requestBody,
    }: {
        promptId: string,
        requestBody: PromptUpdate,
    }): CancelablePromise<PromptOut> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/prompts/{id}',
            query: {
                'prompt_id': promptId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
