// src/NotebookCell.tsx
import React, { forwardRef } from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';
import { CellType } from './types';
import CodeCell from './cells/CodeCell';
import RichTextCell, { RichTextCellRef } from './cells/RichTextCell';
import * as Y from 'yjs';


interface NotebookCellProps {
  cell: Y.Map<any>;
  isSelected: boolean;
  onSelect: (ref: RichTextCellRef | null) => void;
  onSelectionChange?: (formats: any) => void;
}

const NotebookCell = forwardRef<RichTextCellRef | null, NotebookCellProps>(
  ({ cell, isSelected, onSelect, onSelectionChange }) => {
    const borderColor = useColorModeValue(
      isSelected ? 'blue.500' : 'gray.200',
      isSelected ? 'blue.300' : 'gray.600'
    );
    const cellType = cell.get('type');
    const cellContent = cell.get('content');

    // Local ref to RichTextCell
    const localRef = React.useRef<RichTextCellRef | null>(null);

    const handleSelect = () => {
      if (cellType === CellType.RICH_TEXT) {
        onSelect(localRef.current);
      } else {
        onSelect(null);
      }
    };

    const renderCellContent = () => {
      switch (cellType) {
        case CellType.CODE:
          return <CodeCell content={cellContent} />;
        case CellType.RICH_TEXT:
          return (
            <RichTextCell
              ref={localRef}
              content={cellContent}
              onSelectionChange={onSelectionChange}
            />
          );
        default:
          return null;
      }
    };

    return (
      <Box
        className="cell"
        borderWidth={isSelected ? "3px" : "2px"}
        borderColor={borderColor}
        borderRadius="md"
        p={4}
        cursor="pointer"
        onClick={handleSelect}
        bg={useColorModeValue('white', 'gray.800')}
        transition="border-color 0.2s"
        _hover={{ borderColor: 'blue.300' }}
      >
        {renderCellContent()}
      </Box>
    );
  }
);

export default NotebookCell;
