/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InternalModelsOut } from '../models/InternalModelsOut';
import type { ModelCreate } from '../models/ModelCreate';
import type { ModelOut } from '../models/ModelOut';
import type { ModelsOut } from '../models/ModelsOut';
import type { SystemResponse } from '../models/SystemResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ModelsService {

    /**
     * Read Models
     * Retrieve models.
     * @returns ModelsOut Successful Response
     * @throws ApiError
     */
    public static readModels({
        experimentId,
        skip,
        limit = 100,
    }: {
        experimentId: string,
        skip?: number,
        limit?: number,
    }): CancelablePromise<ModelsOut> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/models/',
            headers: {
                'experiment-id': experimentId,
            },
            query: {
                'skip': skip,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Model
     * Create new model.
     * @returns ModelOut Successful Response
     * @throws ApiError
     */
    public static createModel({
        experimentId,
        requestBody,
    }: {
        experimentId: string,
        requestBody: ModelCreate,
    }): CancelablePromise<ModelOut> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/models/',
            headers: {
                'experiment-id': experimentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Internal Models
     * Retrieve internal models.
     * @returns InternalModelsOut Successful Response
     * @throws ApiError
     */
    public static readInternalModels({
        skip,
        limit = 100,
    }: {
        skip?: number,
        limit?: number,
    }): CancelablePromise<InternalModelsOut> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/models/internal',
            query: {
                'skip': skip,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Model
     * Get model by ID.
     * @returns ModelOut Successful Response
     * @throws ApiError
     */
    public static readModel({
        id,
    }: {
        id: string,
    }): CancelablePromise<ModelOut> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/models/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Model
     * Delete an model.
     * @returns SystemResponse Successful Response
     * @throws ApiError
     */
    public static deleteModel({
        id,
    }: {
        id: string,
    }): CancelablePromise<SystemResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/models/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
