import {_RoomProps} from '../client/models/_RoomProps';
import { createSlice } from '@reduxjs/toolkit';

interface RoomState {
  activeRoom: _RoomProps | null;
}

const initialState: RoomState = {
  activeRoom: null,
};

const roomSlice = createSlice({
  name: 'activeRoom',
  initialState,
  reducers: {
    setActiveRoom: (state, action: { payload: _RoomProps | null }) => {
      state.activeRoom = action.payload;
    },
    clearActiveRoom: (state) => {
      state.activeRoom = null;
    },
  },
});

export const { setActiveRoom, clearActiveRoom } = roomSlice.actions;
export default roomSlice.reducer;


// Function to load state from localStorage
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('activeRoom');
    if (serializedState === null || serializedState === undefined) {
      return undefined;
    }
    return { room: { activeRoom: JSON.parse(serializedState) } };
  } catch (err) {
    console.error('Could not load state', err);
    return undefined;
  }
};

// Function to save state to localStorage
export const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state.room.activeRoom);
    localStorage.setItem('activeRoom', serializedState);
  } catch (err) {
    console.error('Could not save state', err);
  }
};