import React, { useState, useEffect, useRef } from 'react';
import {
  HStack,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import {
  ArrowUpIcon,
  ArrowDownIcon,
  DeleteIcon,
  ChevronDownIcon,
  HamburgerIcon,
} from '@chakra-ui/icons';
import {
  FaBold,
  FaItalic,
  FaUnderline,
  FaStrikethrough,
  FaListOl,
  FaListUl,
  FaHeading,
} from 'react-icons/fa';

import SplitButton from '../UI/SplitButton';

// Helper component to render NotebookToolbar buttons with Tooltip
const NotebookToolbarButton = ({
  label,
  icon,
  onClick,
  isDisabled,
  currentFormat,
  buttonSize = 'sm',
}: {
  label: string;
  icon: any;
  onClick: () => void;
  isDisabled: boolean;
  currentFormat?: boolean;
  buttonSize?: string;
}) => (
  <Tooltip label={label} aria-label={label}>
    <IconButton
      aria-label={label}
      icon={icon}
      onClick={onClick}
      isDisabled={isDisabled}
      variant={currentFormat ? 'solid' : 'outline'}
      colorScheme={currentFormat ? 'blue' : 'gray'}
      size={buttonSize}
      m={1}
    />
  </Tooltip>
);

// Debounce utility to delay function execution
const debounce = (func: Function, delay: number) => {
  let timeout: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

interface NotebookToolbarProps {
  onAddCodeCell: () => void;
  onAddRichTextCell: () => void;
  onMoveUp: () => void;
  onMoveDown: () => void;
  onDeleteCell: () => void;
  isTextSelected: boolean;
  isCellSelected: boolean;
  onBold: () => void;
  onItalic: () => void;
  onUnderline: () => void;
  onStrikethrough: () => void;
  onOrderedList: () => void;
  onBulletList: () => void;
  onInsertLink: () => void; //todo
  onInsertImage: () => void; //todo
  onHeading: (level: number | null) => void;
  onFontSize: (size: string) => void;
  onFontFamily: (family: string) => void;
  currentFormats: any;
}

const NotebookToolbar: React.FC<NotebookToolbarProps> = ({
  onAddCodeCell,
  onAddRichTextCell,
  onMoveUp,
  onMoveDown,
  onDeleteCell,
  isTextSelected,
  isCellSelected,
  onBold,
  onItalic,
  onUnderline,
  onStrikethrough,
  onOrderedList,
  onBulletList,
  onHeading,
  onFontSize,
  onFontFamily,
  currentFormats,
}) => {
  // Define available font sizes and families
  const fontSizes = ['12px', '14px', '16px', '18px', '20px', '24px', '28px', '32px', '36px'];
  const fontFamilies = [
    { label: 'Arial', value: 'Arial' },
    { label: 'Courier New', value: 'CourierNew' },
    { label: 'Georgia', value: 'Georgia' },
    { label: 'Times New Roman', value: 'TimesNewRoman' },
    { label: 'Verdana', value: 'Verdana' },
    { label: 'Helvetica', value: 'Helvetica' },
    { label: 'Tahoma', value: 'Tahoma' },
    { label: 'Impact', value: 'Impact' },
    { label: 'Lucida Console', value: 'LucidaConsole' },
  ];

  const notebookToolbarRef = useRef<HTMLDivElement>(null);
  const [notebookToolbarWidth, setNotebookToolbarWidth] = useState<number>(0);

  // Update notebookToolbar width on resize
  useEffect(() => {
    if (notebookToolbarRef.current) {
      const debouncedUpdateWidth = debounce((width: number) => {
        setNotebookToolbarWidth(width);
      }, 15); // in ms

      const resizeObserver = new ResizeObserver((entries) => {
        if (entries[0].contentRect) {
          debouncedUpdateWidth(entries[0].contentRect.width);
        }
      });

      resizeObserver.observe(notebookToolbarRef.current);

      return () => resizeObserver.disconnect(); // Cleanup the observer on unmount
    }
  }, []);

  const COLLAPSE_THRESHOLDS = [700, 500, 200];

  // Define button data for rendering
  const notebookCellMgmtButtons = [
    { label: 'Move Up', icon: <ArrowUpIcon />, onClick: onMoveUp },
    { label: 'Move Down', icon: <ArrowDownIcon />, onClick: onMoveDown },
    { label: 'Delete Cell', icon: <DeleteIcon />, onClick: onDeleteCell },
  ]

  const notebookToolbarButtons = [
    { label: 'Bold', icon: <FaBold />, onClick: onBold, currentFormat: currentFormats.bold },
    { label: 'Italic', icon: <FaItalic />, onClick: onItalic, currentFormat: currentFormats.italic },
    { label: 'Underline', icon: <FaUnderline />, onClick: onUnderline, currentFormat: currentFormats.underline },
    { label: 'Strikethrough', icon: <FaStrikethrough />, onClick: onStrikethrough, currentFormat: currentFormats.strike },
    { label: 'Ordered List', icon: <FaListOl />, onClick: onOrderedList, currentFormat: currentFormats.list === 'ordered' },
    { label: 'Bullet List', icon: <FaListUl />, onClick: onBulletList, currentFormat: currentFormats.list === 'bullet' },
  ];

  const btnSize = "sm";

  const renderHeadingAndFontMenus = () => (
    <>
      {/* Headings and Font Menus */}
      <Menu>
        <MenuButton my="auto" mx={1} size={btnSize}  as={Button} rightIcon={<ChevronDownIcon />} leftIcon={<FaHeading />} isDisabled={!isTextSelected}>
          {currentFormats.header ? `Heading ${currentFormats.header}` : 'Normal'}
        </MenuButton>
        <MenuList>
          <MenuItem onClick={() => onHeading(null)}>
            <Text>Normal</Text>
          </MenuItem>
          <MenuItem onClick={() => onHeading(1)}>
            <Text as="h1" fontSize="2xl">Heading 1</Text>
          </MenuItem>
          <MenuItem onClick={() => onHeading(2)}>
            <Text as="h2" fontSize="xl">Heading 2</Text>
          </MenuItem>
          <MenuItem onClick={() => onHeading(3)}>
            <Text as="h3" fontSize="lg">Heading 3</Text>
          </MenuItem>
          <MenuItem onClick={() => onHeading(4)}>
            <Text as="h4" fontSize="md">Heading 4</Text>
          </MenuItem>
          <MenuItem onClick={() => onHeading(5)}>
            <Text as="h5" fontSize="sm">Heading 5</Text>
          </MenuItem>
        </MenuList>
      </Menu>

      {/* Font Family Menu */}
      <Menu>
        <MenuButton my="auto" mx={1} size={btnSize} as={Button} rightIcon={<ChevronDownIcon />} isDisabled={!isTextSelected}>
          {currentFormats.font || 'Arial'}
        </MenuButton>
        <MenuList>
          {fontFamilies.map((font) => (
            <MenuItem key={font.value} onClick={() => onFontFamily(font.value)}>
              <Text fontFamily={font.value} fontSize="md">
                {font.label}
              </Text>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>

      {/* Font Size Menu */}
      <Menu>
        <MenuButton my="auto" mx={1} size={btnSize} as={Button} rightIcon={<ChevronDownIcon />} isDisabled={!isTextSelected}>
          {currentFormats.size || '14px'}
        </MenuButton>
        <MenuList>
          {fontSizes.map((size) => (
            <MenuItem key={size} onClick={() => onFontSize(size)}>
              {size}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );

  const renderMenu = (renderMain: boolean) => {
    // The ordering in MainMenu determines in what order they get collapsed
    const mainMenu: any[] = [notebookCellMgmtButtons, renderHeadingAndFontMenus, notebookToolbarButtons]
    const collapsedMenu: any[] = []

    {/* Take buttons off the main menu for every threshold it fails to achieve */}
    for (let threshold of COLLAPSE_THRESHOLDS){
      if (notebookToolbarWidth < threshold){
        collapsedMenu.push(mainMenu.pop())
      }
    }

    {/* If length is zero it is a callable */}
    if (renderMain) {
      return mainMenu.flatMap((buttons) => buttons.length > 0 ? renderButtons(buttons) : buttons());
    }else{
      return collapsedMenu.flatMap((buttons) => buttons.length > 0 ? renderButtons(buttons) : buttons());
    }
  }

  const renderButtons = (buttons: any[]) => {
    return (
      <>
        {buttons.map((btn) => (
          <NotebookToolbarButton
            key={btn.label}
            label={btn.label}
            icon={btn.icon}
            onClick={btn.onClick}
            isDisabled={!isCellSelected || 
              (isCellSelected 
                && !isTextSelected 
                && !notebookCellMgmtButtons.map(e => e.label).includes(btn.label))}
            currentFormat={btn.currentFormat}
            buttonSize={btnSize}
          />
        ))}
      </>
    );
  };

  return (
    <HStack spacing={0} align="center" ref={notebookToolbarRef} width="100%">
      <SplitButton
        primaryLabel='+'
        primaryAction={onAddRichTextCell}
        menuOptions={[
          { label: "Add Text Cell", onClick: onAddRichTextCell },
          { label: "Add Code Cell", onClick: onAddCodeCell },
        ]}
        size={btnSize}
      />
      {/* Render mainBar if large enough for at least the smallest threshold */}
      {notebookToolbarWidth > COLLAPSE_THRESHOLDS[2] && renderMenu(true) }

      {/* Render collapsed bar if smaller than the largest threshold */}
      {notebookToolbarWidth < COLLAPSE_THRESHOLDS[0] && (
        <Menu>
          <MenuButton size={btnSize} as={IconButton} icon={<HamburgerIcon />} />
          <MenuList minWidth="240px">
            <Flex wrap="wrap" justifyContent="center" p={2}>
              {renderMenu(false)}
            </Flex>
          </MenuList>
        </Menu>
      )}
    </HStack>
  );
};

export default NotebookToolbar;
