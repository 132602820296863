import { _GroupProps } from "../../client";

export const getGroupByRoomId = (roomId: string, groups: _GroupProps[] | undefined) : _GroupProps | undefined => { 
  return groups?.find(group => 
      group.rooms.some(room => room.id === roomId)
    );
  };

export const getNumMessagesInRoom = (groups :_GroupProps[] | undefined, roomId: string) : number => {
    const group = getGroupByRoomId(roomId, groups);
    const room = group?.rooms.find(room=> room.id == roomId);
    return room?.totalMessageCount || 0;
}