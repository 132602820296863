import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Heading,
  Input,
  List,
  ListItem,
  Text,
  HStack,
  Avatar,
} from '@chakra-ui/react';
import {
  useConfirmRejectFriendshipMutation,
  useGetPendingRequestsQuery,
  useSearchUsersByQuery,
  useRequestFriendshipMutation
} from '../../store/friendshipApi';
import { FriendshipStatus } from '../../client';
import { v4 as uuidv4 } from 'uuid';

interface ManageFriendsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ManageFriendsModal: React.FC<ManageFriendsModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const { data: pendingFriends = [] } = useGetPendingRequestsQuery();
  const [confirmRejectFriendship] = useConfirmRejectFriendshipMutation();
  const [sendFriendRequest] = useRequestFriendshipMutation();
  const { data: searchResults = [], isLoading: searchIsLoading} = useSearchUsersByQuery(searchQuery, {
    skip: !searchQuery
  });

  const handleConfirm = async (friendshipId: uuidv4, action: FriendshipStatus) => {
    try {
      await confirmRejectFriendship({ friendshipId, action });
      // Optionally, refetch data after successful confirmation/rejection
    } catch (error) {
      console.error('Error confirming/rejecting friendship:', error);
      // Handle error appropriately (e.g., display an error message)
    }
  };

  const handleSendRequest = async (friend_id: uuidv4) => {
    try { 
      console.log("requesting ", friend_id)
      await sendFriendRequest({"userId" : friend_id})
    } catch (error) {
      console.log("Error making friend request", error)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Manage Friends</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            mb={3}
            placeholder="Search name, username, email, or phone number"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {searchIsLoading ? (
            <Text>Searching...</Text>
          ) : searchResults?.length > 0 ? (
            <List>
              {searchResults.map((user) => (
                <ListItem key={user.id} display="flex" alignItems="center">
                  <Avatar src={user.avatarUrl || ''} mr={2} />
                  <Text>{user.full_name || user.username || user.email}</Text>
                  <Button ml="auto" onClick={() => handleSendRequest(user.id) }>Add Connection</Button>
                </ListItem>
              ))}
            </List>
          ) : searchQuery ? (
            <Text>No search results</Text>
          ) : null}
          <Heading mb={2} mt={4} size="md">Pending Connections</Heading>
          {pendingFriends.length > 0 ? (
            <>
              <List>
                {pendingFriends.map((friendship) => (
                  <ListItem key={friendship.friend.id} display="flex" alignItems="center">
                    <Avatar src={friendship.friend.avatarUrl || ''} mr={2} />
                    <Text>{friendship.friend.full_name || friendship.friend.username || friendship.friend.email}</Text>
                    <HStack ml="auto">
                      <Button onClick={() => handleConfirm(friendship.id, 'confirmed')}>Confirm</Button>
                      <Button onClick={() => handleConfirm(friendship.id, 'rejected')}>Reject</Button>
                    </HStack>
                  </ListItem>
                ))}
              </List>
            </>
          ) : (
            <Text>No pending friend requests.</Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ManageFriendsModal;
