import { Flex } from '@chakra-ui/react'
import { Outlet, createFileRoute, redirect } from '@tanstack/react-router'

// import Sidebar from '../components/Common/Sidebar'
// import ExperimentBar from '../components/Common/ExperimentBar'
import GroupDrawer from '../components/GroupDrawer/GroupDrawer'
// import NavigationBar from '../components/Common/NavigationBar'
import useAuth, { isLoggedIn } from '../hooks/useAuth'

export const Route = createFileRoute('/_layout')({
  component: Layout,
  beforeLoad: async () => {
    if (!isLoggedIn()) {
      throw redirect({
        to: '/login',
      })
    }
  },
})

function Layout() {
  // const { isLoading } = useAuth()
  useAuth()

  return (
    <Flex minH="100vh" >
      <GroupDrawer/>
      <Flex direction="column" flex="1" overflow="hidden" h="100vh">
        {/* <NavigationBar /> */}
        <Flex flex="1" overflowY="auto" mt={{ base: '12', md: '5' }}>
          <Outlet />
        </Flex>
      </Flex>
    </Flex>

      // <Flex height="100vh">
      //   <Box width="250px" bg="gray.200" p={4}>
      //     <VStack align="stretch">
      //       <Text mb={4}>Menu Item 1</Text>
      //       <Text mb={4}>Menu Item 2</Text>
      //       <Text mb={4}>Menu Item 3</Text>
      //     </VStack>
      //   </Box>

      //   <Flex direction="column" flex="1" overflow="hidden">
      //     {/* Horizontal Navigation Bar */}
      //     <Box height="50px" bg="blue.300" p={4} boxShadow="md">
      //       Navigation Bar
      //     </Box>

      //     <Box flex="1" bg="gray.100" p={4} overflowY="auto">
      //       Content Goes Here
      //     </Box>
      //   </Flex>
      // </Flex>
  )
}

export default Layout
