// src/cells/CodeCell.tsx
import React, { useEffect, useRef } from 'react';
import * as Y from 'yjs';
import { yCollab } from 'y-codemirror.next';
import { Awareness } from 'y-protocols/awareness.js';
import { EditorState } from '@codemirror/state';
import { EditorView, lineNumbers, keymap } from '@codemirror/view';
import { defaultKeymap } from '@codemirror/commands';
import { javascript } from '@codemirror/lang-javascript';
import { oneDark } from '@codemirror/theme-one-dark';

interface CodeCellProps {
  content: Y.Text;
}

const CodeCell: React.FC<CodeCellProps> = ({ content }) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const viewRef = useRef<EditorView | null>(null);

  useEffect(() => {
    if (editorRef.current && content && content.doc) {
      const ydoc = content.doc;
      const awareness = new Awareness(ydoc);

      // Initialize the editor state with the content of Y.Text
      const state = EditorState.create({
        doc: content.toString(),
        extensions: [
          javascript({ jsx: true }),
          yCollab(content, awareness),
          oneDark,
          lineNumbers(),
          EditorView.lineWrapping,
          keymap.of(defaultKeymap),
        ],
      });

      // Create the editor view
      const view = new EditorView({
        state,
        parent: editorRef.current,
      });

      // Store the view instance for potential future use
      viewRef.current = view;

      // Clean up when the component unmounts or content changes
      return () => {
        view.destroy();
      };
    }
  }, [content]);

  return <div ref={editorRef}></div>;
};

export default CodeCell;
