import {
    Flex,
    Spinner,
    Text,
    Box,
    Heading,
    Grid,
    useColorModeValue
  } from '@chakra-ui/react'
  import { createFileRoute } from '@tanstack/react-router'
  import { useQuery } from 'react-query'
  // import { DashboardStats } from '../../client/models/DashboardStats'
  import { ApiError, DashboardService } from '../../client'
  // import ActionsMenu from '../../components/Common/ActionsMenu'
  import useCustomToast from '../../hooks/useCustomToast'
  import { Link } from '@tanstack/react-router'
  import Heatmap from '../../components/Visualizations/heatmap'
  import Scatterplot from '../../components/Visualizations/scatterplot'
  import HeaderWithTooltip from '../../components/Visualizations/HeaderWithTooltip'
  import { useState } from 'react'

  export const Route = createFileRoute('/_layout/dashboard')({
    component: Variables,
  })

  function Variables() {
    const showToast = useCustomToast()
    const statsQuery = useQuery('dashboard', () => DashboardService.getDashboardStats({experimentId: localStorage.getItem('experiment_id') || ''}))

    if (statsQuery.isError) {
      const errDetail = (statsQuery.error as ApiError).body?.detail
      showToast('Something went wrong.', `${errDetail}`, 'error')
    }

    const stats = statsQuery.data

    // Extract keys for column headers
    // const headers = ["model_name", "prompt_text", "score", "total_comparisons"];

    const selectableColor = useColorModeValue('ui.light.main', 'ui.dark.main')

    // // Prepare rows data
    // const rows = stats?.model_name.map((_, index) => {
    //   return headers.map((header) => (stats as any)[header][index]);
    // }) || [];

    const data = stats?.heatmap || [];

    const numCompleted = stats?.num_completed || 0;
    const numTotal = stats?.num_total || "-";

    const [heatmapHeight, setHeatmapHeight] = useState('400px'); // Default height

    // Adjust the height of the heatmap container based on the content
    const updateHeatmapHeight = (height) => {
      setHeatmapHeight(`${height}px`);
    };

    return (
      <>
        {(statsQuery.isLoading)? (
          // TODO: Add skeleton
          <Flex justify="center" align="center" height="100vh" width="full">
            <Spinner size="xl" color="ui.main" />
          </Flex>
        ) : (
          stats ? (
            <Box px={5} width={"100%"} overflowX="auto">
              <Heading size="lg" textAlign={{ base: 'center', md: 'left' }}
                display={{ base: 'inherit', md: 'none' }}
                mb={8}>
                Dashboard
              </Heading>
              {/* First Row */}
              <Grid templateColumns="repeat(2, 1fr)" gap={4} mb={4} minH="100px">
                <Box  p={3} borderRadius="md">
                  <HeaderWithTooltip headerText="Completed Comparisons" tooltipLabel="How many of the possible comparisons have you done" />
                  {numCompleted}/{numTotal} <br></br>
                  How many comparisons you've completed divided by the total number possible.
                </Box>
                <Box p={3} borderRadius="md">
                  <HeaderWithTooltip headerText="Score" tooltipLabel="How the Score Metric is calculated" />
                  The Score Metric is calculated as the ratio of times the model output was preferred over the total number of times it was presented as an option.
                </Box>
              </Grid>

              {/* Second Row */}
              <Grid templateColumns="repeat(1, 1fr)" gap={4} mb={4} width={"100%"} minH={heatmapHeight}>
                <Box p={3} borderRadius="md">
                  <HeaderWithTooltip headerText="Score Heatmap" tooltipLabel="Which conditions you prefer the most. The number is the Score (see above)." />
                  <Heatmap data={data} onHeightChange={updateHeatmapHeight} isLoading={statsQuery.isLoading}/>
                </Box>
              </Grid>
              {/* Third Row */}
              <Grid templateColumns="repeat(1, 1fr)" gap={4} mb={4} width={"100%"}>
                <Box p={3} borderRadius="md">
                  <HeaderWithTooltip headerText="Score vs Cost" tooltipLabel="Which conditions you prefer the most. The number is the Score (see above)." />
                  <Scatterplot data={data}/>
                </Box>
              </Grid>
            </Box>
          ) : (
            localStorage.getItem('experiment_live') === 'true' ?
              <Flex flexDirection="column" alignItems="center" p={4} mt={10} width="100%"> <Text fontSize="xl">Submit an <Link color={selectableColor} to={"/variables"}>experiment configuration</Link>,
              do <Link color={selectableColor} to={"/compare"}>some evaluations</Link>, then check back here</Text></Flex>
              : <Flex justify="center" align="center" height="100vh" width="full"> <Text fontSize="xl" m={3}>Your experiment is loading</Text>
              <Spinner size="xl" color="ui.main" />
            </Flex>
          )
        )}
      </>
    )
  }

  export default Variables
