import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import baseQuery from "./base";
import { _GroupListProp, GroupCreate, GroupMembershipCreate, GroupRead } from '../client';
import { Group } from '../components/GroupDrawer/types';

export const groupAPI = createApi({
  reducerPath: 'groupApi',
  baseQuery: fetchBaseQuery(baseQuery),
  tagTypes: ['Group'],
  endpoints: (builder) => ({
    getGroups: builder.query<_GroupListProp, void>({
      query: () => ({
        url : `/api/v1/groups/`,
        method: 'GET'
      }),
      transformErrorResponse: (response: any) => response as _GroupListProp,
      providesTags: ['Group'],
    }),
    createGroup: builder.mutation<GroupRead, GroupCreate>({
      query: (groupData: GroupCreate) => ({
        'url': '/api/v1/groups/',
        method: 'POST',
        body: groupData
      }),
      invalidatesTags: ['Group']
    }),
    addMemberToGroup: builder.mutation<Group, GroupMembershipCreate>({
      query: (groupMembership: GroupMembershipCreate) => ({
        url: `api/v1/groups/${groupMembership.group_id}/add_member`,
        method: 'POST',
        body: groupMembership
      })
    })
  }),
});

export const { useGetGroupsQuery, useCreateGroupMutation, useAddMemberToGroupMutation } = groupAPI;
