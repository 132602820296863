import {
  Box,
  Container,
  Flex,
  Spinner,
  useColorModeValue,
  FormControl,
  FormErrorMessage,
  Input,
  Button,
} from '@chakra-ui/react'
import { createFileRoute } from '@tanstack/react-router'

import { UsersService } from '../../client'
import { SubmitHandler, useForm } from 'react-hook-form'
import LogoText from '../../components/Common/LogoText'

type ReferralForm = {
  email: string
}


export const Route = createFileRoute('/_layout/referrer')({
  component: Referrer
})


function Referrer() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ReferralForm>({
    mode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: {
      email: '',
    },
  })
  const btnBg = useColorModeValue('ui.light.main', 'ui.dark.main')
  const btnHover = useColorModeValue('ui.light.main2', 'ui.dark.main2')


  const onSubmit: SubmitHandler<ReferralForm> = async (data) => {
    try {
      await UsersService.createReferral({email: data.email})
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      {(false) ? (
        // TODO: Make this page hidden to non-superusers
        <Flex justify="center" align="center" height="100vh" width="full">
          <Spinner size="xl" color="ui.main" />
        </Flex>
      ) : (
        <>
      <Container
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        h="100vh"
        maxW="sm"
        alignItems="stretch"
        justifyContent="center"
        gap={4}
        centerContent
      >

        <Box maxH="15vh" mb={10}>
          <LogoText boxSize='125px' fontSize='5xl' />
        </Box>
        <FormControl id="email" isInvalid={!!errors.email}>
          <Input
            id="email"
            {...register('email', {
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Invalid email address',
              },
            })}
            placeholder="Email"
            type="email"
          />
          {errors.email && (
            <FormErrorMessage>{errors.email.message}</FormErrorMessage>
          )}
        </FormControl>
        <Button
          bg={btnBg}
          variant={'primary'}
          _hover={{ bg: btnHover}}
          type="submit"
          isLoading={isSubmitting}
        >
          Send referral
        </Button>
      </Container>
    </>
      )}
    </>
  )
}

export default Referrer
