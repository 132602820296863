import React from 'react';
import { Flex, Image, Text } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/react';
import Logo from '../../assets/images/logo_dark.svg';
import '@fontsource/comfortaa';

interface LogoTextProps {
    boxSize?: string; //passed to a Chakra Image
    fontSize?: string; //passed to a Chakra Text
}

const LogoText: React.FC<LogoTextProps> = ({ 
    boxSize = "50px",
    fontSize = "3xl"
}) => {
  const iluvatar_theme = useColorModeValue('ui.light.dark', 'ui.dark.iluvatar_theme');

  return (
    <Flex 
      alignItems="center"    // Vertically aligns logo and text
      justifyContent="center" // Horizontally centers the entire Flex container
      width="100%"           // Ensures the container spans the available width
      my={2}
    >
      {/* Logo */}
      <Image
        boxSize={boxSize}  // Ensures the logo is always a square
        src={Logo}
        alt="Logo"
        filter="drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5))"
      />

      {/* Text next to the logo */}
      <Text 
        color={iluvatar_theme} 
        fontSize={fontSize} 
        fontFamily="Comfortaa" 
        ml={3}  // Adds spacing between the logo and the text
      >
        iluvatar
      </Text>
    </Flex>
  );
};

export default LogoText;
