import React from 'react';
import { Flex, Box, Text, IconButton, Tooltip, Spinner } from '@chakra-ui/react';
import { GoKebabHorizontal } from 'react-icons/go';
import { useGetGroupsQuery } from '../../store/groupApi';
import { useColorModeValue } from '@chakra-ui/react';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';

interface ChatHeaderProps {
  onSettingsClick?: () => void;
}

const ChatHeader: React.FC<ChatHeaderProps> = ({ onSettingsClick }) => {
  const activeRoom = useSelector((state : RootState) => state.room.activeRoom);
  const { data, isLoading: groupsLoading, isError } = useGetGroupsQuery();

  const groups = data?.groups || [];

  const roomName = activeRoom?.name;
  let groupInfo = '';

  if (groups && activeRoom) {
    const group = groups.find((group) => group.rooms.some((room) => room.id === activeRoom?.id));
    groupInfo = group?.name || '';
  }

  if (isError) {
    groupInfo = 'Error loading group data';
  }

  return (
    <Flex
      align="center"
      justify="space-between"
      p={3}
      border="1px solid"
      borderColor={useColorModeValue("ui.light.b1", "ui.dark.b1")}
    >
      {(groupsLoading) && <Spinner />}
      {/* Left side: Room Name */}
      <Text fontSize="lg" fontWeight="bold">
        {roomName}
      </Text>

      {/* Center: Group Info */}
      <Box>
        <Text fontSize="md" fontWeight="bold">
          {groupInfo}
        </Text>
      </Box>

      {/* Right side: Settings Button */}
      <Tooltip label="Room Settings" aria-label="Room Settings">
        <IconButton
          opacity={"0%"}
          icon={<GoKebabHorizontal />}
          aria-label="Room Settings"
          variant="ghost"
          onClick={onSettingsClick}
        />
      </Tooltip>
    </Flex>
  );
};

export default ChatHeader;
