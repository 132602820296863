import React from 'react';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import ChatHeader from './ChatHeader';  // Import the new ChatHeader component
import { Flex, Divider } from '@chakra-ui/react';
import { _GroupProps, _RoomProps } from '../../client';

interface ChatAreaProps {
  activeRoom: _RoomProps
  activeGroup: _GroupProps
}

const ChatArea: React.FC<ChatAreaProps> = ({activeRoom, activeGroup}) => {

  // const activeRoom = useSelector((state: RootState) => state.room.activeRoom);

  return (
    <Flex
      direction="column"
      height="100%"  // Full viewport height
    >
      {/* ChatHeader component goes above MessageList */}
      <ChatHeader 
        onSettingsClick={() => console.log('Settings clicked')}  // Handle settings click
      />

      <MessageList
        activeRoom={activeRoom} 
        activeGroup={activeGroup}
      />
      <Divider width={"95%"} mx={"auto"}/>
      <MessageInput />
    </Flex>
  );
};

export default ChatArea;
