import React from 'react'

import {
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  Box,
  Input,
  Text,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react'
import {
  createFileRoute,
  redirect,
} from '@tanstack/react-router'
import { SubmitHandler, useForm } from 'react-hook-form'
import { UsersService } from '../client'
import { useState } from 'react';

// import Logo from '../assets/images/logo_dark.svg'
import { Body_login_login_access_token as AccessToken } from '../client/models/Body_login_login_access_token'
import { isLoggedIn } from '../hooks/useAuth'

import LogoText from '../components/Common/LogoText'

export const Route = createFileRoute('/waitlist')({
  component: Waitlist,
  beforeLoad: async () => {
    if (isLoggedIn()) {
      throw redirect({
        to: '/',
      })
    }
  },
})

function Waitlist() {
  const [error] = React.useState<string | null>(null)
  const [showSuccess, setShowSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<AccessToken>({
    mode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: {
      username: '',
      password: '',
    },
  })

  const onSubmit: SubmitHandler<AccessToken> = async (data) => {
    await UsersService.wailist({ email: data.username})
    setShowSuccess(true);
  }

  const btnBg = useColorModeValue('ui.light.main', 'ui.dark.main')
  const btnHover = useColorModeValue('ui.light.main2', 'ui.dark.main2')


  return (
    <>
    <Flex direction="column" justifyContent="center" alignItems="center" height="100vh">
      {showSuccess ? <Text>Thanks for signing up! You'll hear from us soon.</Text> :
      <Container
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        h="100vh"
        maxW="sm"
        alignItems="stretch"
        justifyContent="center"
        gap={4}
        centerContent
      >
        <Box maxH="15vh" mb={10}>
        <LogoText boxSize='125px' fontSize='5xl' />
        </Box>
        <FormControl id="username" isInvalid={!!errors.username || !!error}>
          <Input
            id="username"
            {...register('username', {
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Invalid email address',
              },
            })}
            placeholder="Email"
            type="email"
          />
          {errors.username && (
            <FormErrorMessage>{errors.username.message}</FormErrorMessage>
          )}
        </FormControl>
        <Button
          bg={btnBg}
          color="white"
          _hover={{ bg: btnHover }}
          type="submit"
          isLoading={isSubmitting}
        >
          Join Waitlist
        </Button>
      </Container>
}

</Flex>
    </>
  )
}

export default Waitlist
