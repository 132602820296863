import { Box, Container, Text } from '@chakra-ui/react'
import { useQueryClient } from 'react-query'
import { createFileRoute, redirect } from '@tanstack/react-router'
// import { Variables } from './variables'

import { UserOut } from '../../client'

export const Route = createFileRoute('/_layout/')({
  component: FakePage,
  beforeLoad: async () => {
    throw redirect({
      to: '/workspace',
    })
  },
})

function FakePage() {
  const queryClient = useQueryClient()

  const currentUser = queryClient.getQueryData<UserOut>('currentUser')

  return (
    <>
      <Container maxW="full">
        <Box pt={2}>
          <Text fontSize="2xl">
            Hi, {currentUser?.full_name || currentUser?.email} 👋🏼
          </Text>
          <Text>Welcome back, nice to see you again!</Text>
        </Box>
      </Container>
    </>
  )
}

export default FakePage
