const baseQuery = { 
    baseUrl: import.meta.env.VITE_API_URL,
    prepareHeaders: (headers) => {
        const token = localStorage.getItem('access_token');
        headers = new Headers();
        headers.set('Accept', 'application/json')
        headers.set('Authorization', `Bearer ${token}`);
        headers.set('Content-Type', 'application/json');
        return headers;
    },
};

export default baseQuery;