import React from 'react';
import { Box, Text, useColorModeValue } from '@chakra-ui/react';
import { Link } from '@tanstack/react-router';
import {_RoomProps} from '../../client/models/_RoomProps';
import { setActiveRoom } from '../../store/roomSlice';
import { RootState } from '../../store/store';
import { useSelector, useDispatch } from 'react-redux';

interface RoomItemProps {
  room: _RoomProps;
  collapseDrawer: () => void;
}

const RoomItem: React.FC<RoomItemProps> = ({room, collapseDrawer }) => {
  const bgColor = useColorModeValue('ui.light.b1', 'ui.dark.b1');
  const activeColor = useColorModeValue('ui.light.b3', 'ui.dark.b2'); 
  const hoverColor = useColorModeValue('ui.light.b2', 'ui.dark.b3');

  const activeRoom = useSelector((state: RootState) => state.room.activeRoom);
  const dispatch = useDispatch();

  collapseDrawer; // suppress warning but want this here for future

  const roomClicked = () => {
    handleRoomSelect(room)
  }

  const handleRoomSelect = (roomKey: _RoomProps) => {
    dispatch(setActiveRoom(roomKey));
  };

  return (
    <Link to="/workspace">
      <Box
        bg={room.id === (activeRoom?.id || '') ? activeColor : bgColor}
        borderRadius="md"
        cursor="pointer"
        _hover={{ bg: hoverColor }}
        onClick={roomClicked}
      >
        <Text pl={6} fontSize="md">{room.name}</Text>
      </Box>
    </Link>
  );
};

export default RoomItem;
