import React from 'react';
import { Avatar, VStack, Text, HStack, Badge, Flex, Button } from '@chakra-ui/react';
import { FaRobot } from 'react-icons/fa';
import { IoMdRefresh } from "react-icons/io";
import remarkGfm from 'remark-gfm';
import Markdown from 'react-markdown';

interface MessageItemProps {
  sender: string;
  message: string;
  avatarUrl?: string;
  isBot?: boolean;  // New prop to indicate if the message is from a bot
  showSenderInfo?: boolean; // Added prop to conditionally show sender info
  status: 'pending' | 'succeeded' | 'failed'; // TODO UI for showing status of message
}

const MessageItem: React.FC<MessageItemProps> = ({ sender, message, avatarUrl, isBot, showSenderInfo = true, status }) => {
  // const renderMessage = (message: string) => {
  //   console.log(message);
  //   const parts = message.split(/(@\w+)/g);
  //   return parts.map((part, index) => {
  //     if (part.startsWith('@')) {
  //       return <Text as="span" key={index} color="blue.500">{part}</Text>;
  //     }
  //     return <Text as="span" key={index}>{part}</Text>;
  //   });
  // };

  return (
    <HStack
      align="flex-start"
      spacing="4"
      w="full"
      pt={showSenderInfo ? 2 : 0}
      borderRadius="md"
    >
      {/* Avatar for both human and bot */}
      <Avatar
        opacity={showSenderInfo ? 100 : 0}
        name={sender}
        src={avatarUrl}
        height={showSenderInfo ? "40px" : "0px"}
        width={"40px"}
        icon={isBot ? <FaRobot /> : undefined}  // Show robot icon for bot avatars
      />

      {/* Name and message */}
      <VStack align="stretch" spacing="0" w="full">
        <HStack>
          <Text 
            hidden={!showSenderInfo} 
            fontWeight="bold" 
            fontSize="md" fontStyle={isBot ? "italic" : "normal"} 
            >
            {sender}
          </Text>
          {isBot && (
            <Badge colorScheme="teal" fontSize="xs">
              AI
            </Badge>
          )}
        </HStack>
        <Flex width="90%">
          <Text as="span"
            color={status ? status === 'pending' ? 'gray.400' : (status === 'failed' ? 'red' : undefined): undefined}
          >
            <Markdown remarkPlugins={[remarkGfm]} 
            // components={{
            //     p: props => <>{renderMessage(props.children?.toString() || '')}</>
            // }}
          >
              {message}
            </Markdown>   
          </Text>
          {status === 'failed' && (
            <Button my="auto" ml="auto" color={"red"} size="xs" bg={"transparent"}><IoMdRefresh /></Button>
          )}
        </Flex>
      </VStack>
    </HStack>
  );
};

export default MessageItem;
