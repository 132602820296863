import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  useToast,
  VStack,
  List,
  ListItem,
  Text,
  Avatar,
} from '@chakra-ui/react';
import { useAddMemberToGroupMutation } from '../../store/groupApi';
import { useSearchUsersByQuery } from '../../store/friendshipApi';
import { UUID } from 'crypto';

interface GroupSettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
  groupName: string;
  groupId: string;
}

const GroupSettingsModal: React.FC<GroupSettingsModalProps> = ({ isOpen, onClose, groupName, groupId }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const { data: searchResults = [], isLoading: searchIsLoading } = useSearchUsersByQuery(searchQuery, {
    skip: !searchQuery,
  });
  const [addMemberToGroup] = useAddMemberToGroupMutation();
  const toast = useToast();
  const [selectedUser, setSelectedUser] = useState<UUID | string | null>(null);

  const handleAddMember = (new_member_id: UUID | string) => {
    try {
      addMemberToGroup({
        group_id: groupId,
        user_id: new_member_id,
        role: 'member',
      });
      setSearchQuery('');
      setSelectedUser(null);
    } catch (e) {
      console.log('Failed to add member to group ', e);
      toast({
        title: 'Error',
        status: 'error',
        description: 'Failed to add member to group',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Group Settings for {groupName}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Add a Member</FormLabel>
              <Input
                placeholder="Search name, username, email, or phone number"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              {searchIsLoading ? (
                <Text>Searching...</Text>
              ) : searchResults?.length > 0 ? (
                <List>
                  {searchResults.map((user) => (
                    <ListItem
                      key={user.id}
                      display="flex"
                      alignItems="center"
                      onClick={() => setSelectedUser(user.id)}
                      cursor="pointer"
                      borderBottom="1px solid #ccc"
                      p={2}
                    >
                      <Avatar src={user.avatarUrl || ''} mr={2} />
                      <Text>{user.full_name || user.username || user.email}</Text>
                    </ListItem>
                  ))}
                </List>
              ) : searchQuery ? (
                <Text>No search results</Text>
              ) : null}
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="primary"
            mr={3}
            onClick={() => selectedUser && handleAddMember(selectedUser)}
            disabled={!selectedUser}
          >
            Add Member
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default GroupSettingsModal;
