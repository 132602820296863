// src/components/GroupList.tsx
import React from 'react';
import { VStack } from '@chakra-ui/react';
import GroupItem from './GroupItem';
import { Group } from './types'

interface GroupListProps {
  groups: Group[];
  onSelectGroup: (group: Group) => void;
  onRoomClick: () => void;
  expandedGroupIds: string[];
}

const GroupList: React.FC<GroupListProps> = ({ groups, onSelectGroup, onRoomClick, expandedGroupIds }) => {
  return (
    <VStack spacing={4} align="stretch">
      {groups.map((group) => (
        <GroupItem
          group={group}
          key={group.id}
          isExpanded={expandedGroupIds.includes(group.id)}
          onToggleExpand={() => onSelectGroup(group)}
          onRoomClick={onRoomClick}
        />
      ))}
    </VStack>
  );
};

export default GroupList;
