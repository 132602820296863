import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Notebook, CellUpdate } from '../client';
import baseQuery from './base';
import { CellType } from '../client';

export const notebookApi = createApi({
  reducerPath: 'notebookApi',
  baseQuery: fetchBaseQuery(baseQuery),
  tagTypes: ["Notebook", "Cell"],
  endpoints: (builder) => ({
    getNotebook: builder.query<Notebook, string>({
      query: (id: string) => ({
        url: `/api/v1/notebooks/`,
        params: { notebook_id: id },
        method: 'GET',
      }),
      providesTags: (result) =>
        result ? [{ type: 'Notebook', id: result.id }] : [],
    }),
    updateNotebook: builder.mutation<Notebook, Notebook>({
      query: (notebook) => ({
        url: `/api/v1/notebooks/${notebook.id}`,
        method: 'PUT',
        body: notebook,
      }),
      invalidatesTags: (result, _error, _arg) =>
        result ? [{ type: 'Notebook', id: result.id }] : [],
    }),
    createCell: builder.mutation<CellUpdate, { notebookId: string; content: string; type: CellType }>({
      query: ({ notebookId, content, type }) => ({
        url: `/api/v1/cells/`,
        method: 'POST',
        body: { "notebook_id": notebookId, "content" : content, "type": type },
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Cell', id: arg.notebookId }, { type: 'Notebook', id: arg.notebookId }],
    }),
    readCell: builder.query<CellUpdate, string>({
      query: (id: string) => ({
        url: `/api/v1/cells/${id}`,
        method: 'GET',
      }),
      providesTags: (result) => result ? [{ type: 'Cell', id: result.id }] : [],
    }),
    updateCell: builder.mutation<CellUpdate, CellUpdate>({
      query: (cell) => ({
        url: `/api/v1/cells/${cell.id}`,
        method: 'PUT',
        body: cell,
      }),
      invalidatesTags: (result, _error, _arg) =>
        result ? [{ type: 'Cell', id: result.id }] : [],
    }),
    deleteCell: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/api/v1/cells/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Cell', id: arg }, { type: 'Notebook', id: arg }],
    }),
    getCellsByNotebookId: builder.query<CellUpdate[], string>({
      query: (notebookId: string) => ({
        url: `/api/v1/cells/all`,
        method: 'GET',
        params: {"notebook_id": notebookId}
      }),
      providesTags: (result, _error, arg) => {
        return result
          ? [...result.map(({ id }) => ({ type: 'Cell' as const, id: id })), { type: 'Notebook' as const, id: arg }]
          : [{ type: 'Notebook' as const, id: arg }];
      },
    }),
    moveCellUp: builder.mutation<CellUpdate, CellUpdate>({
      query: (cell) => ({
        url: `/api/v1/cells/${cell.id}/move`,
        method: 'PUT',
        body: { direction: 'up' },
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Cell', id: arg.notebook_id }],
    }),
    moveCellDown: builder.mutation<CellUpdate, CellUpdate>({
      query: (cell) => ({
        url: `/api/v1/cells/${cell.id}/move`,
        method: 'PUT',
        body: { direction: 'down' },
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Cell', id: arg.notebook_id }],
    }),
  }),
});

export const {
  useGetNotebookQuery,
  useUpdateNotebookMutation,
  useCreateCellMutation,
  useReadCellQuery,
  useUpdateCellMutation,
  useDeleteCellMutation,
  useGetCellsByNotebookIdQuery,
  useMoveCellUpMutation,
  useMoveCellDownMutation,
} = notebookApi;
