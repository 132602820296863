import React, { useState, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  VStack,
  HStack,
  Avatar,
  AvatarBadge,
  Box,
  Text,
  IconButton,
  // Switch,
  useSteps,
  useToast,
  Center,
  useColorModeValue,
} from '@chakra-ui/react';
import { CloseIcon, SmallCloseIcon, AddIcon } from '@chakra-ui/icons';
import { useCreateGroupMutation } from '../../store/groupApi';
import { useQueryClient } from 'react-query';
import { UserOut } from '../../client';

interface User {
  id: string;
  fullName: string | null;
  username: string | null;
  email: string | null;
  phoneNumber: string;
  avatarUrl?: string;
}

interface NewGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  contacts: User[];
}

const NewGroupModal: React.FC<NewGroupModalProps> = ({ isOpen, onClose, contacts }) => {
  const queryClient = useQueryClient()
  const currentUser = queryClient.getQueryData<UserOut>('currentUser')
  const userMe = contacts.find((user) => user.id == currentUser?.id)
  if (!userMe){
    return
  }
  const [email, setEmail] = useState('');
  const [selectedUsers, setSelectedUsers] = useState<User[]>([userMe]);
  contacts = contacts.filter((user) => user.id !== currentUser?.id)
  const [filteredContacts, setFilteredContacts] = useState<User[]>(contacts);
  const [groupName, setGroupName] = useState('');
  const [groupDescription, setGroupDescription] = useState('');
  const [avatarUrl, setAvatarUrl] = useState('');
  const [createGroup] = useCreateGroupMutation();
  // const [autoReply, setAutoReply] = useState(false);
  // const [defaultModel, setDefaultModel] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const steps = ['Add Members', 'Group Settings'];
  const { activeStep, setActiveStep } = useSteps({ index: 0 });
  const toast = useToast();

  const handleAddUser = (user: User) => {
    setSelectedUsers([...selectedUsers, user]);
    setEmail('');
    setFilteredContacts(filteredContacts.filter((contact) => contact.id !== user.id));
  };

  const handleAddNewUser = () => {
    const newUser = {
      id: email,
      fullName: '',
      username: '',
      email,
      phoneNumber: '',
      avatarUrl: '',
    };
    setSelectedUsers([...selectedUsers, newUser]);
    setEmail(''); 
  };

  const handleRemoveUser = (user: User) => {
    setSelectedUsers(selectedUsers.filter((u) => u.id !== user.id));
    if (contacts.find((contact) => contact.id === user.id)) {
      setFilteredContacts([...filteredContacts, user]);
    }
  };

  const handleInputChange = (value: string) => {
    setEmail(value);
    setFilteredContacts(
      contacts.filter((contact) => {
        const searchValue = value.toLowerCase();
        return (
          (contact.fullName?.toLowerCase().includes(searchValue) || false) ||
          (contact.username?.toLowerCase().includes(searchValue) || false) ||
          (contact.email?.toLowerCase().includes(searchValue) || false) ||
          contact.phoneNumber.includes(searchValue)
        );
      })
    );
  };

  const handleNextStep = () => setActiveStep(activeStep + 1);
  const handlePreviousStep = () => setActiveStep(activeStep - 1);

  const handleAvatarUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setAvatarUrl(reader.result as string);
      reader.readAsDataURL(file);
      toast({
        title: 'Avatar updated successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAvatarClick = () => {
    fileInputRef.current?.click();
  };

  const handleCreateGroup = async () => {
    try {
      const groupData = {
        name: groupName,
        description: groupDescription,
        avatarUrl,
        members: selectedUsers.map(user => user.id),
        // autoReply,
        // defaultModel,
      };
      await createGroup(groupData)
      toast({
        title: 'Group created successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } catch (error: unknown) { // Handle error type
      toast({
        title: 'Error creating group.',
        description: (error as Error).message, // Access message property
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create a new Group</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {activeStep === 0 && (
            <VStack spacing={4} align="start">
              <FormControl>
                <FormLabel>Selected Members</FormLabel>
                <VStack align="start">
                  {selectedUsers.map((user) => (
                    <HStack key={user.id} spacing={3} w="full">
                      <Avatar size="sm" src={user.avatarUrl} />
                      <Text flex="1">
                        {user.fullName ? user.fullName : user.email}
                      </Text>
                      {user.id !== currentUser?.id && 
                        <IconButton
                          icon={<CloseIcon />}
                          size="xs"
                          aria-label="Remove user"
                          onClick={() => handleRemoveUser(user)}
                        />
                      }
                    </HStack>
                  ))}
                </VStack>
              </FormControl>

              <FormControl>
                <FormLabel>Add members to the group</FormLabel>
                <HStack spacing={2}>
                  <Input
                    placeholder="Enter name, username, email, or phone number"
                    value={email}
                    onChange={(e) => handleInputChange(e.target.value)}
                  />
                  <Button
                    variant="primary"
                    onClick={handleAddNewUser}
                    isDisabled={!email || !!filteredContacts.find((contact) => contact.email === email)}
                  >
                    Add
                  </Button>
                </HStack>
              </FormControl>

              <VStack align="start" spacing={1} w="full" maxH="200px" overflowY="auto">
                {filteredContacts && filteredContacts.length > 0 ? (
                  filteredContacts.map((contact) => (
                    <HStack
                      key={contact.id}
                      spacing={3}
                      w="full"
                      p={2}
                      borderRadius="md"
                      _hover={{ bg: useColorModeValue('gray.100', 'gray.800') }}
                      cursor="pointer"
                      onClick={() => handleAddUser(contact)}
                    >
                      <Avatar size="sm" src={contact.avatarUrl} />
                      <Box>
                        <Text fontWeight="bold">{contact.fullName || contact.username || contact.email}</Text>
                        <Text fontSize="sm" color="gray.500">
                          {contact.email}
                        </Text>
                      </Box>
                    </HStack>
                  ))
                ) : (
                  <Text color="gray.500">No contacts found</Text>
                )}
              </VStack>
            </VStack>
          )}

          {activeStep === 1 && (
            <VStack spacing={4} align="start">
              <HStack w="full" spacing={4} align="center">
                <FormControl w="auto">
                  <FormLabel>Group Avatar</FormLabel>
                  <Box 
                    position="relative" 
                    cursor="pointer" 
                    onClick={handleAvatarClick}
                    borderRadius="full"
                    transition="all 0.2s"
                    _hover={{
                      transform: "scale(1.05)",
                      "& > .avatar": {
                        bg: "gray.200",
                      },
                      "& > .avatar .chakra-icon": {
                        color: "gray.600",
                      }
                    }}
                  >
                    <Avatar 
                      size="lg" 
                      src={avatarUrl} 
                      bg="gray.100"
                      className="avatar"
                      transition="all 0.2s"
                    >
                      {!avatarUrl && (
                        <Center position="absolute" top="0" left="0" right="0" bottom="0">
                          <AddIcon boxSize={6} color="gray.500" transition="all 0.2s" />
                        </Center>
                      )}
                      {avatarUrl && (
                        <AvatarBadge
                          as={IconButton}
                          size="sm"
                          rounded="full"
                          icon={<SmallCloseIcon />}
                          aria-label="Remove avatar"
                          onClick={(e) => {
                            e.stopPropagation();
                            setAvatarUrl('');
                          }}
                        />
                      )}
                    </Avatar>
                    <Input
                      ref={fileInputRef}
                      type="file"
                      accept="image/*"
                      onChange={handleAvatarUpload}
                      hidden
                    />
                  </Box>
                </FormControl>
                <FormControl flex={1}>
                  <Input
                    placeholder="Enter group name"
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                  />
                </FormControl>
              </HStack>

              <FormControl my={5}>
                <Input
                  placeholder="Enter group description"
                  value={groupDescription}
                  onChange={(e) => setGroupDescription(e.target.value)}
                />
              </FormControl>

              {/* <Text fontWeight="bold" mb={2}>
                  Group Options (can be changed):
              </Text>
              <FormControl display="flex" alignItems="center">
                <FormLabel textAlign={"left"} mb="0">AI auto reply. Should AI agents in this chat spontaneously participate rather than being explicitly (@)-tagged</FormLabel>
                <Switch isChecked={autoReply} onChange={() => setAutoReply(!autoReply)} />
              </FormControl>

              <FormControl display="flex" alignItems="center">
                <FormLabel mb="0">Default Model</FormLabel>
                <Switch isChecked={defaultModel} onChange={() => setDefaultModel(!defaultModel)} />
              </FormControl> */}

              <Box w="full">
                <Text fontWeight="bold" mb={2}>
                  Members:
                </Text>
                <VStack align="start">
                  {selectedUsers.map((user) => (
                    <HStack key={user.id} spacing={3}>
                      <Avatar size="sm" src={user.avatarUrl} />
                      <Text>{user.fullName ? user.fullName : user.email}</Text>
                    </HStack>
                  ))}
                </VStack>
              </Box>
            </VStack>
          )}
        </ModalBody>

        <ModalFooter>
          {activeStep > 0 && (
            <Button variant="ghost" mr={3} onClick={handlePreviousStep}>
              Back
            </Button>
          )}
          {activeStep < steps.length - 1 ? (
            <Button variant="primary" onClick={handleNextStep} isDisabled={activeStep === 0 && selectedUsers.length === 0}>
              Next
            </Button>
          ) : (
            <Button variant="primary" onClick={handleCreateGroup} isDisabled={!groupName}>
              Create Group
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NewGroupModal;
