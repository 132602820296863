import React from 'react';
import {
  Button,
  ButtonGroup,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

// SplitButton component
interface SplitButtonProps {
  primaryLabel: string; // Label for the primary button
  primaryAction: () => void; // Function for the primary button action
  menuOptions: { label: string; onClick: () => void }[]; // Array of menu options
  size?: string;
}

const SplitButton: React.FC<SplitButtonProps> = ({
  primaryLabel,
  primaryAction,
  menuOptions,
  size,
}) => {
  return (
    <ButtonGroup isAttached>
      {/* Primary button */}
      <Button size={size} onClick={primaryAction} colorScheme="blue">
        {primaryLabel}
      </Button>

      {/* Dropdown menu on the right */}
      <Menu>
        <MenuButton size={size} as={IconButton} icon={<ChevronDownIcon />} colorScheme="blue" />
        <MenuList>
          {menuOptions.map((option, index) => (
            <MenuItem key={index} onClick={option.onClick}>
              {option.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </ButtonGroup>
  );
};

export default SplitButton;
