// src/components/AdminBar.tsx
import React, { useState } from 'react';
import { HStack, IconButton, Link, Tooltip } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
// import { FaUserFriends } from 'react-icons/fa';
import { useQuery } from 'react-query';

import NewGroupModal from './NewGroupModal';
import { UsersService, UserOut } from '../../client'; // Import UserOut
import ManageFriendsModal from './ManageFriendsModal';
import { FiSettings } from 'react-icons/fi';

interface User {
  id: string;
  fullName: string;
  username: string;
  email: string | null;
  phoneNumber: string;
  avatarUrl?: string;
  is_active?: boolean;
  is_superuser?: boolean;
  industry?: string | null;
  company?: string | null;
  occupation?: string | null;
  created_at?: string;
  updated_at?: string;
}

interface AdminBarProps {}

const AdminBar: React.FC<AdminBarProps> = () => {
  const [isNewGroupOpen, setisNewGroupOpen] = useState(false);
  const [isManageFriendsOpen, setIsManageFriendsOpen] = useState(false);

  const { data: usersData } = useQuery('users', () => UsersService.readUsers({ skip: 0, limit: 100 }));

  const handleNewGroup = () => setisNewGroupOpen(true);
  const handleCloseNewGroup = () => setisNewGroupOpen(false);
  // const handleOpenManageFriends = () => setIsManageFriendsOpen(true);
  const handleCloseManageFriends = () => setIsManageFriendsOpen(false);

  const onAddGroup = () => {
    handleNewGroup();
  };

  // const onManageFriends = () => {
  //   handleOpenManageFriends();
  // };

  const mapUserOutToUser = (userOut: UserOut): User => ({
    id: userOut.id,
    fullName: userOut.full_name || '',
    username: userOut.username || '',
    email: userOut.email,
    phoneNumber: userOut.phoneNumber || '',
    avatarUrl: userOut.avatarUrl || '',
    is_active: userOut.is_active,
    is_superuser: userOut.is_superuser,
    industry: userOut.industry,
    company: userOut.company,
    occupation: userOut.occupation,
    created_at: userOut.created_at,
    updated_at: userOut.updated_at,
  });

  const users = usersData?.data.map(mapUserOutToUser) || [];

  return (
    <>
      <HStack spacing={2} mb={4} justify={'space-between'}>
        <HStack justify={'flex-start'}>
          <Tooltip label="Add Group" aria-label="Add Group">
            <IconButton
              icon={<AddIcon />}
              variant="ghost"
              colorScheme='orange'
              onClick={onAddGroup}
              aria-label="Add Group"
            />
          </Tooltip>
          {/* <Tooltip label="Manage Contacts" aria-label="Manage Contacts">
            <IconButton
              icon={<FaUserFriends />}
              variant="ghost"
              colorScheme='orange'
              onClick={onManageFriends}
              aria-label="Manage Contacts"
            />
          </Tooltip> */}
        </HStack>
        <Tooltip label="Settings" aria-label="Settings">
            <IconButton
              icon={<FiSettings />}
              as={Link}
              href="/settings"
              variant="ghost"
              colorScheme='orange'
              aria-label="Settings"
            />
          </Tooltip>
      </HStack>
      <NewGroupModal isOpen={isNewGroupOpen} onClose={handleCloseNewGroup} contacts={users} />
      <ManageFriendsModal isOpen={isManageFriendsOpen} onClose={handleCloseManageFriends}/>
    </>
  );
};

export default AdminBar;
