// src/components/GroupItem.tsx
import React, { useState } from 'react';
import { Box, Text, Flex, Avatar, Collapse, VStack, IconButton, Tooltip } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { GoKebabHorizontal } from "react-icons/go";
import RoomItem from './RoomItem'; 
import GroupSettingsModal from './GroupSettingsModal'; 
import { _RoomProps } from '../../client/models/_RoomProps';

interface GroupItemProps {
  group: {
    id: string;
    name: string;
    avatarUrl?: string | null;
    latestMessage?: string;
    unreadCount?: number | null;
    rooms: _RoomProps[];
  };
  isExpanded: boolean;
  onToggleExpand: () => void;
  onRoomClick: () => void;
}

const GroupItem: React.FC<GroupItemProps> = ({ group, isExpanded, onToggleExpand, onRoomClick }) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false); // Modal state for settings

  const handleOpenSettings = () => setIsSettingsOpen(true);
  const handleCloseSettings = () => setIsSettingsOpen(false);

  return (
    <Box>
      <Flex
        align="center"
        justify="space-between"
        cursor="pointer"
        // p={3}
        // bg={isExpanded ? activeColor : bgColor}
        borderRadius="md"
        onClick={onToggleExpand}
      >
        <Flex align="center">
          {/* Group Avatar */}
          {group.avatarUrl && <Avatar src={group.avatarUrl} size="sm" mr={3} />}
          <Text fontWeight="medium">{group.name}</Text>
        </Flex>

        <Flex align="center">
          {/* Settings Button */}
          <Tooltip label="Group Settings" aria-label="Group Settings">
            <IconButton
              icon={<GoKebabHorizontal />}
              colorScheme="gray"
              variant="ghost"
              aria-label="Settings"
              onClick={(e) => {
                e.stopPropagation(); // Prevent the click from toggling the rooms
                handleOpenSettings();
              }}
            />
          </Tooltip>
          {/* Chevron for expanding/collapsing */}
          {isExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
        </Flex>
      </Flex>

      {/* Rooms List (Collapsed/Expanded) */}
      <Collapse in={isExpanded} animateOpacity>
        <VStack spacing={2} align="stretch" mt={2}>
          {group.rooms.map((room) => (
            <RoomItem 
              room={room}
              key={room.id}
              collapseDrawer={onRoomClick}
              />
          ))}
        </VStack>
      </Collapse>

      {/* Group Settings Modal */}
      <GroupSettingsModal
        isOpen={isSettingsOpen}
        onClose={handleCloseSettings}
        groupName={group.name}
        groupId={group.id}
      />
    </Box>
  );
};

export default GroupItem;
