// src/cells/RichTextCell.tsx
import React, {
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Box } from '@chakra-ui/react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import MarkdownShortcuts from 'quill-markdown-shortcuts';
import * as Y from 'yjs';
import { QuillBinding } from 'y-quill';

// Register the markdown shortcuts module
Quill.register('modules/markdownShortcuts', MarkdownShortcuts);

// Register custom fonts with class-friendly names
const Font = Quill.import('formats/font') as any;
Font.whitelist = [
  'Arial',
  'CourierNew',
  'Georgia',
  'TimesNewRoman',
  'Verdana',
  'Helvetica',
  'Tahoma',
  'Impact',
  'LucidaConsole',
];
Quill.register(Font, true);

// Register custom sizes
const Size = Quill.import('attributors/style/size') as any;
Size.whitelist = [
  '12px',
  '14px',
  '16px',
  '18px',
  '20px',
  '24px',
  '28px',
  '32px',
  '36px',
];
Quill.register(Size, true);

export interface RichTextCellRef {
  toggleBold: () => void;
  toggleItalic: () => void;
  toggleUnderline: () => void;
  toggleStrike: () => void;
  toggleList: (ordered: boolean) => void;
  insertLink: (url: string) => void;
  insertImage: (url: string) => void;
  toggleHeader: (level: number | null) => void;
  setFontSize: (size: string) => void;
  setFontFamily: (family: string) => void;
}

interface RichTextCellProps {
  content: Y.Text;
  onSelectionChange?: (formats: any) => void;
}

const RichTextCell = forwardRef<RichTextCellRef, RichTextCellProps>(
  ({ content, onSelectionChange }, ref) => {
    const quillRef = React.useRef<ReactQuill>(null);

    useEffect(() => {
      const editor = quillRef.current?.getEditor();
      if (editor && content) {
        const binding = new QuillBinding(content, editor);

        return () => {
          binding.destroy();
        };
      }
    }, [content]);

    // Define default formats
    const defaultFormats = {
      font: 'Arial',
      size: '14px',
      header: null,
      bold: false,
      italic: false,
      underline: false,
      strike: false,
      list: false,
    };

    // Define mapping between heading levels and font sizes
    const headingSizeMap: { [key: number]: string } = {
      1: '32px',
      2: '24px',
      3: '18px',
      4: '16px',
      5: '14px',
    };

    // Handle selection changes
    const handleSelectionChange = (
      range: any,
    ) => {
      setTimeout(() => {
        if (range) {
          const editor = quillRef.current?.getEditor();
          const formats = editor?.getFormat(range);
          if (formats && Object.keys(formats).length > 0) {
            // If a heading is applied, override the size
            if (formats.header) {
              const size = headingSizeMap[formats.header as number] || '14px';
              editor?.format('size', size);
              onSelectionChange && onSelectionChange({ ...formats, size });
            } else {
              onSelectionChange && onSelectionChange(formats);
            }
          } else {
            // No specific formats applied, use default
            onSelectionChange && onSelectionChange(defaultFormats);
          }
        } else {
          // Selection lost focus
          onSelectionChange && onSelectionChange({});
        }
      }, 100);
    };

    // Handle focus event to capture initial formats
    const handleFocus = () => {
      setTimeout(() => {
        const editor = quillRef.current?.getEditor();
        if (editor) {
          const range = editor.getSelection();
          if (range) {
            const formats = editor.getFormat(range);
            if (formats && Object.keys(formats).length > 0) {
              // If a heading is applied, override the size
              if (formats.header) {
                const size = headingSizeMap[formats.header as number] || '14px';
                editor.format('size', size);
                onSelectionChange && onSelectionChange({ ...formats, size });
              } else {
                onSelectionChange && onSelectionChange(formats);
              }
            } else {
              onSelectionChange && onSelectionChange(defaultFormats);
            }
          }
        }
      }, 100); // Delay to allow Quill to set selection
    };

    // Expose editor commands to parent via ref
    useImperativeHandle(ref, () => ({
      toggleBold: () => {
        const editor = quillRef.current?.getEditor();
        editor?.format('bold', !editor.getFormat().bold);
      },
      toggleItalic: () => {
        const editor = quillRef.current?.getEditor();
        editor?.format('italic', !editor.getFormat().italic);
      },
      toggleUnderline: () => {
        const editor = quillRef.current?.getEditor();
        editor?.format('underline', !editor.getFormat().underline);
      },
      toggleStrike: () => {
        const editor = quillRef.current?.getEditor();
        editor?.format('strike', !editor.getFormat().strike);
      },
      toggleList: (ordered: boolean) => {
        const editor = quillRef.current?.getEditor();
        const format = ordered ? 'ordered' : 'bullet';
        const currentList = editor?.getFormat().list;
        editor?.format('list', currentList === format ? false : format);
      },
      insertLink: (url: string) => {
        const editor = quillRef.current?.getEditor();
        const selection = editor?.getSelection();
        if (selection) {
          editor?.format('link', url);
        }
      },
      insertImage: (url: string) => {
        const editor = quillRef.current?.getEditor();
        const selection = editor?.getSelection();
        if (selection) {
          editor?.insertEmbed(selection.index, 'image', url, 'user');
        }
      },
      toggleHeader: (level: number | null) => {
        const editor = quillRef.current?.getEditor();
        const currentHeader = editor?.getFormat().header;
        if (currentHeader === level) {
          // Remove header and reset size
          editor?.format('header', false);
          editor?.format('size', '14px'); // Default size
          onSelectionChange &&
            onSelectionChange({ ...defaultFormats, size: '14px' });
        } else {
          // Apply header and set corresponding size
          editor?.format('header', level);
          if (level !== null && headingSizeMap[level]) {
            editor?.format('size', headingSizeMap[level]);
            onSelectionChange &&
              onSelectionChange({
                header: level,
                size: headingSizeMap[level],
                font: editor?.getFormat().font || 'Arial',
                bold: editor?.getFormat().bold || false,
                italic: editor?.getFormat().italic || false,
                underline: editor?.getFormat().underline || false,
                strike: editor?.getFormat().strike || false,
                list: editor?.getFormat().list || false,
              });
          }
        }
      },
      setFontSize: (size: string) => {
        const editor = quillRef.current?.getEditor();
        editor?.format('size', size);
      },
      setFontFamily: (family: string) => {
        const editor = quillRef.current?.getEditor();
        editor?.format('font', family);
      },
    }));

    // Capture initial format when the component mounts or the editor is ready
    useEffect(() => {
      const editor = quillRef.current?.getEditor();
      if (editor) {
        const range = editor.getSelection();
        if (range) {
          const formats = editor.getFormat(range);
          if (formats && Object.keys(formats).length > 0) {
            // If a heading is applied, override the size
            if (formats.header) {
              const size = headingSizeMap[formats.header as number] || '14px';
              editor.format('size', size);
              onSelectionChange && onSelectionChange({ ...formats, size });
            } else {
              onSelectionChange && onSelectionChange(formats);
            }
          } else {
            onSelectionChange && onSelectionChange(defaultFormats);
          }
        }
      }
    }, [quillRef, onSelectionChange]);

    return (
      <Box borderRadius="md" bg={'transparent'} cursor="text">
        <ReactQuill
          ref={quillRef}
          theme="bubble"
          placeholder=''
          onChangeSelection={handleSelectionChange} // Add selection change handler
          onFocus={handleFocus} // Add focus handler
          modules={{
            toolbar: false,
            markdownShortcuts: true,  // Enable live Markdown formatting
            clipboard: {
              matchVisual: false,
            },
          }}
          formats={[
            'header',
            'font',
            'size',
            'bold',
            'italic',
            'underline',
            'strike',
            'list',
            'bullet',
            'link',
            'image',
          ]}
        />
      </Box>
    );
  }
);

export default RichTextCell;
