// src/Notebook.tsx
import React, { useState, useEffect, useRef } from 'react';
import { VStack, Box, Button, Heading, Input, useColorModeValue } from '@chakra-ui/react';
import { CellType } from './types';
import NotebookCell from './NotebookCell';
import NotebookToolbar from './NotebookToolbar';
import { RichTextCellRef } from './cells/RichTextCell';
// import { CloseIcon } from '@chakra-ui/icons';
import { useGetNotebookQuery, useUpdateNotebookMutation } from '../../store/notebookApi';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import * as Y from 'yjs';
import { WebsocketProvider } from 'y-websocket';

interface NotebookProps {
  allocatedWidth: number;
}

const Notebook: React.FC<NotebookProps> = ({allocatedWidth}) => {
  const activeRoom = useSelector((state: RootState) => state.room.activeRoom);
  const {data: notebook} = useGetNotebookQuery(activeRoom?.notebook_id || '');
  const [updateNotebook] = useUpdateNotebookMutation();

  const [selectedCellId, setSelectedCellId] = useState<string | null>(null);
  const [selectedCellType, setSelectedCellType] = useState<CellType | null>(null);
  const [currentFormats, setCurrentFormats] = useState<any>({});
  const editorRef = useRef<RichTextCellRef | null>(null);
  const notebookToolbarRef = useRef<HTMLDivElement>(null); 
  const [isEditingTitle, setIsEditingTitle] = useState(false); 
  const [notebookTitle, setNotebookTitle] = useState(notebook?.name); 
  const [isNotebookOpen, setIsNotebookOpen] = useState(true); 

  const [cells, setCells] = useState<Y.Map<any>[]>([]);
  const ydocRef = useRef<Y.Doc | null>(null);
  const providerRef = useRef<WebsocketProvider | null>(null);
  const yCellsRef = useRef<Y.Array<Y.Map<any>> | null>(null);

  const notebookId = activeRoom?.notebook_id

  useEffect(() => {
    console.log("NotebookID is : ", notebookId)
    if (notebookId) {
      // Initialize Y.Doc
      const ydoc = new Y.Doc();
      ydocRef.current = ydoc;

      const baseUrl = import.meta.env.VITE_API_URL.split('//')[1];
      const protocol = window.location.protocol.includes('https') ? 'wss://' : 'ws://';

      // Initialize WebsocketProvider
      const provider = new WebsocketProvider(
        `${protocol}${baseUrl}/yjs`, // Replace with your yjs-server address
        notebookId, // Unique room name per notebook
        ydoc
      );
      providerRef.current = provider;

      // Get or create the shared cells array
      const yCells = ydoc.getArray<Y.Map<any>>('cells');
      yCellsRef.current = yCells;

      // Observe changes to the Y.Array and update local state
      const handleYCellsChange = () => {
        setCells(yCells.toArray());
      };
      yCells.observeDeep(handleYCellsChange);

      // Initialize local state
      handleYCellsChange();

      // Clean up on unmount
      return () => {
        yCells.unobserveDeep(handleYCellsChange);
        provider.destroy();
        ydoc.destroy();
      };
    }
  }, [activeRoom]);

  useEffect(() => {
    setNotebookTitle(notebook?.name)
  }, [notebook])

  // Add a new cell
  const addCell = (type: CellType, index?: number) => {
    if (yCellsRef.current) {
      const newCell = new Y.Map();
      newCell.set('id', `${Date.now()}`);
      newCell.set('type', type);
      newCell.set('content', new Y.Text());
  
      if (typeof index === 'number') {
        yCellsRef.current.insert(index, [newCell]);
      } else {
        yCellsRef.current.push([newCell]);
      }
    }
  };


  // Delete a cell
  const deleteCellFromNotebook = () => {
    if (selectedCellId && yCellsRef.current) {
      const index = yCellsRef.current
        .toArray()
        .findIndex((cell) => cell.get('id') === selectedCellId);
      if (index !== -1) {
        yCellsRef.current.delete(index, 1);
        setSelectedCellId(null);
        setSelectedCellType(null);
        editorRef.current = null;
        setCurrentFormats({});
      }
    }
  };

  // Move a cell up
  const moveCellUpHandler = () => {
    if (selectedCellId && yCellsRef.current) {
      const cellsArray = yCellsRef.current.toArray();
      const index = cellsArray.findIndex((cell) => cell.get('id') === selectedCellId);
      if (index > 0) {
        yCellsRef.current.delete(index, 1);
        yCellsRef.current.insert(index - 1, [cellsArray[index]]);
      }
    }
  };

  // Move a cell down
  const moveCellDownHandler = () => {
    if (selectedCellId && yCellsRef.current) {
      const cellsArray = yCellsRef.current.toArray();
      const index = cellsArray.findIndex((cell) => cell.get('id') === selectedCellId);
      if (index !== -1 && index < cellsArray.length - 1) {
        yCellsRef.current.delete(index, 1);
        yCellsRef.current.insert(index + 1, [cellsArray[index]]);
      }
    }
  };

  // Select a cell and set the editorRef
  const selectCell = (id: string, ref: RichTextCellRef | null) => {
    console.log("Selecting cell ", id, ref)
    setSelectedCellId(id);
    const cellType = cells.find((cell) => cell.get('id') === id)?.get('type');
    setSelectedCellType(cellType ? (cellType as CellType) : null);
    editorRef.current = ref;
  };

  // Handle selection change from RichTextCell
  const handleSelectionChange = (formats: any) => {
    setCurrentFormats(formats);
  };

  // Handlers for global notebookToolbar buttons
  const handleBold = () => {
    editorRef.current?.toggleBold();
  };

  const handleItalic = () => {
    editorRef.current?.toggleItalic();
  };

  const handleUnderline = () => {
    editorRef.current?.toggleUnderline();
  };

  const handleStrikethrough = () => {
    editorRef.current?.toggleStrike();
  };

  const handleOrderedList = () => {
    editorRef.current?.toggleList(true);
  };

  const handleBulletList = () => {
    editorRef.current?.toggleList(false);
  };

  const handleInsertLink = () => {
    const url = prompt('Enter the URL');
    if (url) {
      editorRef.current?.insertLink(url);
    }
  };

  const handleInsertImage = () => {
    const url = prompt('Enter the image URL');
    if (url) {
      editorRef.current?.insertImage(url);
    }
  };

  const handleHeading = (level: number | null) => {
    console.log(editorRef.current)
    editorRef.current?.toggleHeader(level);
  };

  const handleFontSize = (size: string) => {
    editorRef.current?.setFontSize(size);
  };

  const handleFontFamily = (family: string) => {
    editorRef.current?.setFontFamily(family);
  };

  // Open/Close notebook
  const toggleNotebook = () => {
    setIsNotebookOpen((prevState) => !prevState);
  };

  // Double-click to edit notebook title
  const handleDoubleClick = () => {
    setIsEditingTitle(true);
  };

  // Save notebook title on Enter or when input loses focus
  const handleTitleSave = (e: React.KeyboardEvent | React.FocusEvent) => {
    if (
      (e as React.KeyboardEvent).key === 'Enter' ||
      (e as React.FocusEvent).relatedTarget === null
    ) {
      if (notebook){
        updateNotebook({
          "owner_id": notebook?.owner_id,
          "room_id": notebook?.room_id,
          "name": notebookTitle,
          "id": notebook.id
        })
      }
      setIsEditingTitle(false);
    }
  };

  // if (isLoading) return <div>Loading...</div>;

  return (
    <>
    {!isNotebookOpen ? (
      <Box textAlign="center" mt={20}>
        <Button variant="primary" onClick={toggleNotebook}>
          Open Notebook
        </Button>
      </Box>
    ) : (
      <>
      <VStack 
        spacing={4}
        align="stretch"
        maxWidth={allocatedWidth > 30 ? "95%" : "100%"}
        px={4}
        pb={4}
        pt={3}
        bg={useColorModeValue('gray.50', 'gray.600')} 
        border="1px solid gray.300" 
        borderRadius="md" 
        boxShadow="md" 
        minH="80vh" 
        mx="auto" 
      >
          
          {/* Notebook Header */}
          <Box
            p={2}
            borderRadius="md"
            boxShadow="sm"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom="1px solid teal.300" 
          >
            {isEditingTitle ? (
              <Input
                value={notebookTitle}
                onChange={(e) => setNotebookTitle(e.target.value)}
                onKeyDown={handleTitleSave}
                onBlur={handleTitleSave}
                autoFocus
              />
            ) : (
              <Heading
                as="h3"
                size="md"
                onDoubleClick={handleDoubleClick}
                cursor="pointer"
              >
                {notebookTitle}
              </Heading>
            )}
            {/* <IconButton
              aria-label="Close notebook"
              icon={<CloseIcon />}
              onClick={toggleNotebook}
              size="sm"
            /> */}
          </Box>


          {/* NotebookToolbar Container with Ref */}
          <Box ref={notebookToolbarRef}>
            <NotebookToolbar
              onAddCodeCell={() => addCell(CellType.CODE)}
              onAddRichTextCell={() => addCell(CellType.RICH_TEXT)}
              onMoveUp={moveCellUpHandler}
              onMoveDown={moveCellDownHandler}
              onDeleteCell={deleteCellFromNotebook}
              isTextSelected={selectedCellType === CellType.RICH_TEXT}
              isCellSelected={selectedCellId!==null}
              onBold={handleBold}
              onItalic={handleItalic}
              onUnderline={handleUnderline}
              onStrikethrough={handleStrikethrough}
              onOrderedList={handleOrderedList}
              onBulletList={handleBulletList}
              onInsertLink={handleInsertLink}
              onInsertImage={handleInsertImage}
              onHeading={handleHeading}
              onFontSize={handleFontSize}
              onFontFamily={handleFontFamily}
              currentFormats={currentFormats} 
            />
          </Box>

          {/* Cells */}
          <VStack spacing={4} align="stretch">
          {cells.map((cell) => {
            const cellId = cell.get('id');
            return (
              <NotebookCell
                key={cellId}
                cell={cell}
                isSelected={cellId === selectedCellId}
                onSelect={(ref) => selectCell(cellId, ref)}
                onSelectionChange={handleSelectionChange}
              />
            );
          })}
          </VStack>
        </VStack>
      </>
      )}
    </>
  );
};

export default Notebook;
