import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import baseQuery from "./base";
import { FriendshipOut, FriendshipStatus, UserOut } from '../client';
import { UUID } from 'crypto';


export const friendshipAPI = createApi({
  reducerPath: 'friendshipApi',
  baseQuery: fetchBaseQuery(baseQuery),
  tagTypes: ['Friendship', 'PendingFriends'],
  endpoints: (builder) => ({
    getFriends: builder.query<FriendshipOut[], void>({
      query: () => ({
        url: `/api/v1/friends/all`,
        method: 'GET',
      }),
      providesTags: ['Friendship'],
    }),
    requestFriendship: builder.mutation<void, { userId: UUID | string }>({
      query: ({ userId }) => ({
        url: `/api/v1/friends/add`,
        method: 'POST',
        params: {friend_id: userId},
      }),
      invalidatesTags: ['PendingFriends'],
    }),
    getPendingRequests: builder.query<FriendshipOut[], void>({
      query: () => ({
        url: `/api/v1/friends/all`,
        method: 'GET',
        params: {status: 'pending'}
      }),
      providesTags: ['PendingFriends'],
    }),
    confirmRejectFriendship: builder.mutation<void, { friendshipId: UUID, action: FriendshipStatus }>({
      query: ({ friendshipId, action }) => ({
        url: `/api/v1/friends/respond`,
        params: {
          friendship_id: friendshipId,
          action: action
        },
        method: 'PATCH',
      }),
      invalidatesTags: ['PendingFriends', 'Friendship'],
    }),
    inviteUser: builder.mutation<string, { email: string }>({
      query: ({ email }) => ({
        url: `/api/v1/create_referral`,
        method: 'POST',
        body: { email: email },
      }),
    }),
    searchUsersBy: builder.query<UserOut[], string>({ // TODO: Can probably do caching here
      query: (searchTerm: string) => ({
        url: `/api/v1/users/search`,
        method: 'GET',
        params: {
          search_term: searchTerm
        }
      }),
    }),
  }),
});

export const { 
  useGetFriendsQuery, 
  useRequestFriendshipMutation, 
  useGetPendingRequestsQuery, 
  useConfirmRejectFriendshipMutation, 
  useInviteUserMutation ,
  useSearchUsersByQuery,
} = friendshipAPI;

export default friendshipAPI;
